<style lang="scss" scoped>
.search-address {
    .search-city {

    }

    .address {
        width: 100%;
        color: #8492a6;
        font-size: 13px
    }
}
</style>
<template>
    <div class="search-address">
        <el-autocomplete :size="size" :style="{'width': inputWidth}" v-model="title" @select="selectAddress"
                         :disabled="disabled"
                         :placeholder="placeholder"
                         :fetch-suggestions="remoteMethod"
                         clearable
                         select-when-unmatched>
            <template slot-scope="{ item }">
                <p style="width: 100%">
                    <span style="text-align: left;padding-right: 20px;color: #333;">{{ item.city }}</span>{{
                        item.value
                    }}
                </p>
                <p class="address">{{ item.address }}</p>
            </template>
            <el-select :size="size" v-if="isSlot" slot="prepend" placeholder="请选择" filterable @change="selectCity"
                       :disabled="isSelect" :style="{'width': selectWidth}" v-model="cityCode">
                <el-option
                    v-for="(item,index) in serverCityList"
                    :key="index"
                    :label="item.name"
                    :value="item.name">
                </el-option>
            </el-select>
        </el-autocomplete>

    </div>

</template>

<script type="text/ecmascript-6">
import {localSearch} from '@/common/js/gaodeMap/index';
import {getCityData} from '@/urls/index';

export default {
    //定义模版数据
    data() {
        return {
            title: this.address.title,
            cityCode: this.address.city,
            serverCityList: [],
            selectAddressInfo: {}
        }
    },
    props: {
        isSlot: {//显示城市搜索
            type: Boolean,
            default: false
        },
        disabled: {  // 是否禁用
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '请填写地址'
        },
        selectWidth: {//城市搜索的宽度
            type: String,
            default: '110px'
        },
        inputWidth: {//城市搜索的宽度
            type: String,
            default: '490px'
        },
        isSelect: {//城市搜索可选
            type: Boolean,
            default: false
        },
        address: {
            type: Object,
            default: function () {
                return {
                    city: "上海市",
                    title: ""
                }

            }
        },
        indexIdent: {
            type: Object,
            default: function () {
                return {}
            }
        },
        size: {
            type: String,
            default: 'medium'
        }
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.getServerCity();
    },
    //定义事件方法
    methods: {
        remoteMethod(query, cb) {
            if (query) {
                this.loading = true;
                let _this = this;
                this.localList = [];
                this.$nextTick(function () {
                    localSearch(query, _this.cityCode).then(function (res) {
                        _this.localList = res;
                        _this.loading = false;
                        cb(_this.localList);
                    })
                })
            } else {
                this.localList = [];
                let addressInfo = {
                    city: this.cityCode,
                    cityId: (this.serverCityList.find(({city}) => city === this.cityCode) || {}).shortCode
                }
                this.$emit('update:searchAddress', addressInfo)
            }
        },
        async getServerCity() {
            try {
                let res = await getCityData(this, '2');
                if (res) {
                    this.serverCityList = res;
                    this.fullNameOfCity(this.address.city)
                }
            } catch (e) {
                console.log(e);
            }
        },
        selectAddress(val) {
            val.city = this.cityCode;
            val.cityId = (this.serverCityList.find(({city}) => city === this.cityCode) || {}).shortCode;
            this.selectAddressInfo = val;
            this.$emit('update:searchAddress', val, this.indexIdent)
        },
        selectCity() {
            let addressInfo = {
                city: this.cityCode,
                cityId: (this.serverCityList.find(({city}) => city === this.cityCode) || {}).shortCode
            }
            this.selectAddressInfo = addressInfo;
            this.$emit('update:searchAddress', addressInfo)
        },
        fullNameOfCity(cityName) {
            this.serverCityList.forEach((data) => {
                if (data.city.indexOf(cityName) > -1) {
                    this.cityCode = data.city;
                    this.address.city = data.city;
                    this.address.cityId = (this.serverCityList.find(({city}) => city === this.cityCode) || {}).shortCode;
                    this.$emit('update:searchAddress', this.address, this.indexIdent)
                }
            })
        },
        clearAddressMod() {
            this.selectAddressInfo = {};
            this.$emit('update:searchAddressClear')
        }
    },
    //监听模版变量
    watch: {
        address({city = '', title = ''}) {
            if (title)
                this.title = `${city} ${title}`;
            else
                this.title = '';
            this.cityCode = city;
            if (city && city.indexOf('市') === -1) {
                this.fullNameOfCity(city)
            }
        }
    }

}
</script>
